.filter-wrapper{
    margin: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
}

.filter-title{
    width: 100%
}

.filter-title > h4{
    margin: 0;
    width: 100%;
    padding: 0.5rem 0;
    background-color: #262B40;
    color: #fff;
    text-align: center;
    border-radius: 5px 5px 0 0;
}

.filter-selected{
    margin: 1rem 0 1rem 2rem;
    position: relative;
    min-width: 200px;
    align-self: flex-start;
}

.filter-selected-item {
    margin-top: 1rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 5px;
    background-color: #262B40;
    color: #fff;
    cursor: default;
}

.filter-selected > span {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.8rem;    
    cursor: default;
}

.filter-date-wrap{
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    align-self: center;
    gap: 1rem;
}

.inpt-grp{
  display: flex;
  
  height: 30px;
  border: 1px solid #262B40;
  border-radius: 5px;
}

.inpt-grp > span{
  text-align: center;
  align-self: center;
  margin-left: 0.6rem;
  font-weight: 700;
}

.inpt-grp > input{
  display: flex;
  justify-content: center;
  text-align: center;
  align-self: center;
  margin: 0 5px;
  border: none;
  overflow: hidden;
  background-color: inherit;
  
}

.inpt-grp > input,span{
  font-size: 1rem;
}

.input-top{
  margin-right: 0.8rem;
}

.counter-limit{  
  margin:0 0.8rem;
  vertical-align: text-bottom;
  font-weight: 700;
}

.quantity-btn{
  background: #262B40;
  border: none;
  padding: 0.3rem 0.7rem;
  color: #fff;
  transition: all 200ms linear;
  cursor: pointer;
  height: 100%;
}

.filter-btn{
  border-radius: 5px;
  font-size: 1.2rem;
  background: #5a8ace;
  border: none;
  height: 30px;
  color: #fff;
  transition: all 200ms linear;
  cursor: pointer;
}

.filter-btn > i{
  margin-right: 0.3rem;
}

.filter-btn:hover,
.quantity-btn:hover{
  background-color: #1a4a8d;
  border-color: #262B40;
}

.filter-btn:disabled{
  background-color: darkgray;
}

.filter-btn > i{
  margin-left: 1rem;
}



/*********
  flip chart table
*********/

.flip-hr{
  width: 90%;
}

.chart-table{
	margin: 1rem;
	display: flex;
	justify-content: space-around;
	width: 100%;
	max-width: 900px;
}

.chart-table > label{
	color: #262B40;
	font-weight: 700;
	font-size: 1rem;
	width: auto;
	border: #262B40 1px solid;
	appearance: none;
	outline: none;
	cursor: pointer;
	border-radius: 3px;
	transition: all 200ms linear;
}

.chart-table input[type="radio"]:checked+label {
	background: #5a8ace;
  border: none;
	color: #fff;
  font-size: 1rem;
	box-shadow: 0 1px 1px #0000002e;
	text-shadow: 0 1px 0px #79485f7a;
}

.chart-table label:hover {
	background-color:  #1a4a8d;
	color: #fff;
	box-shadow: 0 1px 1px #0000002e;
	text-shadow: 0 1px 0px #79485f7a;
}

.chart-table input{
  display: none;
}

.chart-table > label{
  display: inline-block;
  background-color: #ddd;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
}

/**********
  querys
***********/
@media (max-width: 450px) {
  .filter-date > h6 {
    margin: 5px;
  }
}


@media screen and (max-width: 780px) {

  .filter-date-wrap{
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    gap: 15px;
  }
}