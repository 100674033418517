@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
	font-size: 1.125rem;
  background-color: #fff;
} 

header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 100%;
    background-color:#262B40;
}

.nav{
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  gap: 2rem;
  padding: 0 1rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}

.logout-img{
  max-height: 1.2em;
}

 /* Move reCAPTCHA v3 badge to the left */
 
 .grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}


/**********
hamburger menu
**********/

.container-hamburger {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

/**********
scroll to top button
**********/

.top-button{
  position: fixed;
  bottom: 40px;
  right: 45px; 
  z-index: 1;
  color: #2f46ca;
}


/*********
 Style page content
*********/

.main {
  transition: margin-left .5s; /* If you want a transition effect */
  margin-left: 0rem;
  overflow: hidden;
}

.main-wrapper{
  margin: 2rem;
}

.main p {
  margin: 0;
}


/********* 
main wrapper
**********/

.main-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.block{
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff
}

.block-title{
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

/**********
  querys
***********/
@media (max-width: 450px) {
  .sidebar {
    padding-top: 8rem;
  }

  .date-form {
    width: 85%;
  }

  .date-form tbody{
    width: 100%;
    display: table;
  }

  .date-form td{
    text-align: center;
  }

  .date-form label{
    font-size: 1.3rem;
  }

  .date-form input{
    font-size: 1rem;
  }

  .side-btn{
    font-size: 1.1rem;
  }

  #mysidebar{
    width: 100vw !important;
  }
  .pie > h3{
    margin: 1rem;
    font-size: 1rem;
    text-align: center;
  }
}

@media (max-width: 560px) {
  .bolt-title-h{
    margin-right: 1rem;
    font-size: 1rem;
  }
  .block-title{
    justify-content: flex-end;
  }
}