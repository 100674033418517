/********** 
  Sidebar
**********/

.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  background-color: #262B40; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 5rem; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.sidebar-width{
  width: 100%;
}

.side-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-wrapper > ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.side-wrapper > ul > li >a {
  text-align: center;
  padding: 0.5rem 0;
  text-decoration: none;
  transition: color 400ms;
  color: #fff;
  display: block;
  width: 100%;
  white-space: nowrap;
}

.side-wrapper > ul > li > a:hover {
  text-decoration: none;
  transition: color 400ms;
  color: #fff;
}

.side-wrapper > ul > li:hover {
  text-decoration: none;
  transition: color 400ms;
  background-color: #9c9c9c;
  border-left: 5px solid #fff;
}