.logo{
  width: 70%;
  margin-bottom: 20%;
}

.login-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto auto;
}

.login-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min-content;
  margin: auto;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-form > label,
.login-form > h4{
  margin: 0.3rem 1rem ;
  align-self: flex-start;
}

#login-title{
  align-self: center;
}

.login-form > h4{
  color:  rgb(90, 138, 206);
}

.login-form > label{
  margin-top: 1.5rem;
  font-size: 1rem;
}

.login-form > input{
  margin: 0 0.8rem;
  border-radius: 5px;
  font-size: 1.3rem;
  border: 1px solid rgba(166, 162, 162, 0.601);
  padding: 0.5rem 1rem;
}

.btn{
  margin-top: 2rem;
  border-radius: 3px;
  font-size: 1.1rem;
  background: rgb(90, 138, 206);
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
}

.errorTitle{
  margin: 1rem 0;
  padding:0.5rem 2rem;
  color: white;
  background-color: #a84327;
  border-radius: 5px;;
}

#password{
  border-radius: 3px 3px 0 0;
}

#password:focus{
  outline:none;
}

@media screen and (max-width: 510px) {

  .login-form{
    box-shadow: none;
  }
}