/********
 Not Found
 *******/

.notfound-main{
  height: 100%;
  width: 100%;
}

 .notfound-card{
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 }

.notfound-card > h1{
    font-size: 165px;
    margin: 2rem 2rem 0;
 }

 .notfound-card > h1 > span{
    color: #00b7ff;
 }

 .notfound-card > h2{
  margin: auto 2rem ;
  font-weight: 400;
  text-transform: uppercase;
 }

 .notfound-card > a > button,
 .notfound-card > button{
    margin: 3rem auto;
    font-size: 1.3rem;
    font-weight: 600;
 }