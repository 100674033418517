.block-cikk{
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff
}

.cikk-title{
  width: 100%;
  background-color: #262B40;
  color: #fff;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.cikk-title > h4{
  margin: 0.5rem 0;
}

.cikk-hr{
  margin: 0;
  width: 100%;
}

.tree{
    align-self: center;
    width: 60%;
    margin: 2rem;
}

.tree-leafs{
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(38, 43, 64, 0.2);
}

.tree-leafs:hover{
  border-color: black;
  background-color: #ad4432;
  color: #fff;
}

.tree-leafs:first-child{
  border-radius: 5px 5px 0 0;
}

.tree-leafs:last-child{
  border-radius: 0 0 5px 5px;
}

.tree-leafs > span {
  margin: 0.5rem 0;
}

.fa-caret-down,
.fa-caret-right{
    margin-top:0.3rem;
    margin-right: 1rem;
    margin-left: 0.3rem;
}

.selected-wrap{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.selected-circle{
  background-color: #fff;
  color: rgb(6, 9, 146);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  
  position: relative;
  margin-right: 1rem;
}

#selected-check{
  font-size: 1.4rem;
  position: absolute;
  top: 4px;
  left: 4px;
}

.root-depth{
  background-color: #dac9a7;
  white-space: nowrap
}

.first-depth{
  background-color: #224233;
  color: #fff;
  border-color: #fff;
  white-space: nowrap
}

.second-depth{
  background-color: #7d83b7;
  border-color: #fff;
  white-space: nowrap
}

.third-depth{
  background-color: #80522c;
  color: #fff;
  border-color: #fff;
  white-space: nowrap
}

.fourth-depth{
  background-color: #2d365e;
  color: #fff;
  border-color: #fff;
  white-space: nowrap

}

.cikk-selection{
  width: 100%;
  background-color: #262B40;
  color: #fff;
  text-align: center;
  border-radius: 0 0 5px 5px;
}

.cikk-selection > h4{
  margin: 0.5rem 0;
}