.pwd-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: #e5e4e3;
    margin-top: 1.5rem;
    border-radius: 10px;
}

.pwd-title{
    margin: 1rem 0.5rem 0;
}

.pwd-container ul{
    font-size: 0.9rem;
    margin: 1rem;
    padding: 0;

}

.pwd-container li {
    list-style-type: none;
}

.fa-circle-check{
    color: green;
    margin-right: 0.5rem;
}

.fa-circle-xmark{
    color: red;
    margin-right: 0.5rem;
}