/**********
  Charts
***********/

.bolt-title-h{
  color: #2f46ca;
}

.custom-tooltip{
  padding: 0.5rem;
  background-color: #fff;
  opacity: 80%;
  border: 2px solid rgb(7, 9, 95);
  border-radius: 7px;
}

.custom-tooltip td{
  padding: 0 0.2rem;
  text-align: right;
}

.tooltip-data{
  font-weight: bold;
}

.legend-text{
  margin: 0 0.5rem;
  font-size: 0.8rem;
  opacity: 0.5;
}

/********
PIE CHART

********/

.pie{
  justify-content: center;
}

.pie h3{
  color: #2f46ca;
  margin: 1rem;
  text-align: center;
}

.title-color{
  font-size: 1.17rem;
  color: #262B40;
}

/********
 chart button group
 *******/

.viewform{
  display: flex;
  align-items: baseline;
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 0.3em 0;
  color: #262B40;
}

.viewform > input{
  color: #262B40;
  font-weight: 900;
  width: auto;
  height: 100%;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	cursor: pointer;
	border-radius: 2px;
	padding: 4px 8px;
  transition: all 200ms linear;
  border: #262B40 1px solid;
}

.viewform input:checked {
	background-color: #5a8ace;
	border: none;
	color: #fff;
	box-shadow: 0 1px 1px #0000002e;
	text-shadow: 0 1px 0px #79485f7a;
}

.viewform input:hover {
	background-color: #1a4a8d;;
	color: #fff;
	box-shadow: 0 1px 1px #0000002e;
	text-shadow: 0 1px 0px #79485f7a;
}

.viewform input:before {
	content: attr(label);
	display: inline-block;
	text-align: center;
	width: 100%;
}

.bar{
	flex-direction: column;
    align-items: center;
}

.title-date{
	margin: 1rem;
}

.title-cikk{
	margin: 0;
}

.title-cikk > span,
.title-date > span{
	color: red;
}


