/********
tables
 *******/

 .block-table{
  margin: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff
}

.block-table-title{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  overflow-x: auto;
}

.styled-table {
    border-collapse: collapse;
    margin: 0;
    font-size: 0.9em;
    width: 100%;
}

.table-title-row {
    background-color: #262B40;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    border-bottom: 1px solid white;
}

.table-bolt-row{
  line-height: 30px;
}
.title-color-table{
  color: red;
}

.table-title-row > th{
    padding: 1rem 0;
}

.table-head-row {
  background-color: #262B40;
  color: #ffffff;
  text-align: left;
  white-space: nowrap;
}

.table-title{
  margin: 0;
  padding: 0.5rem 0;
  width: 100%;
  text-align: center;
  background-color: #262B40;
  color: #fff;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid white;
}

.styled-table th,
.styled-table td {
  padding: 0.4rem 1.5rem;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/********
grill
 *******/

 .grill-table th,
 .grill-table td{
  padding: 0.4rem 1rem;
}

.grill-head-row,
.grill-head-second-row,
.logisztika-head-row,
.grill-sum-row,
.grill-table > tbody,
.logisztika-table > tbody{
  text-align: center;
}

.grill-head-row > th:nth-child(n+3),
.grill-head-second-row > th,
.grill-sum-row > th,
.logisztika-head-row > th{
  border: 1px solid white;
}

.grill-table > tbody > tr >td,
.logisztika-table > tbody > tr >td{
  border: 1px solid black;
}

/********
logisztika
 *******/